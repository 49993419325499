@font-face {
  font-family: 'gotham-font-medium';
  src: url(font/GothamRnd-Medium.otf);
  font-display: swap;
}
@font-face {
  font-family: 'gotham-font-bold';
  src: url(font/GothamRnd-Bold.otf);
  font-display: swap;
}
@font-face {
  font-family: 'gotham-font-book';
  src: url(font/GothamRnd-Book.otf);
  font-display: swap;
}
@font-face {
  font-family: 'gotham-font-light';
  src: url(font/GothamRnd-Light.otf);
  font-display: swap;
}

.ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8);
}

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label {
  color: rgba(255, 255, 255, 0.7);
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: #eeeeee;
  /*color: #3c4858;*/
  color: #273943;
  font-size: 14px;
  margin: 0;
  font-weight: 300;
  line-height: 1.5em;
  scrollbar-color: #a1a1a1 #e4e4e4;
  scrollbar-width: thin !important;
}

.css-isyiec-MuiTypography-root-MuiTimelineContent-root {
	line-height: 1.43 !important;
	font-family: 'gotham-font-light' !important;
}

div, textarea {
	scrollbar-color: #a1a1a1 #e4e4e4;
	scrollbar-width: thin;
}

blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

small {
  font-size: 80%;
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
  color: #009df0;
}

h2 {
  font-size: 2.4em;
  color: #009df0;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}

blockquote p {
  font-style: italic;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
  font-weight: 300;
  line-height: 1.5em;
}

body {
  font-family: 'gotham-font-light' !important;
  font-weight: 500;
  font-style: normal;
}

h1,
h2,
.h1,
.h2 {
  font-family: 'gotham-font-medium';
  font-weight: 700;
  margin-bottom: .8rem;
}

h3,
h4,
h5,
h6,
.h3,
.h4 {
  font-family: 'gotham-font-medium';
  font-weight: 700;
  margin-bottom: .8rem;
}

.gMedium {
	font-family: 'gotham-font-medium';
}

button {
  font-weight: 700;
}

a {
  color: #9c27b0;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #89229b;
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

footer {
  padding: 15px 0;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

#root {  
  /*max-width: 1760px;*/
  margin: 0 auto;
}

@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation:portrait){
#root {
  transform: rotate(90deg);
  height: 1028px;
  width: 1369px;
  margin-top: 172px;
  margin-left: -170px;
}
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}

@media (max-width: 991px) {
  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}
.fixed-plugin {
  /*font-family: "Raleway", "Helvetica", "Arial", sans-serif;*/
  font-weight: 300;
  line-height: 1.5em;
  position: fixed;
  top: 180px;
  right: 0;
  width: 64px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
  top: 120px;
}

.fixed-plugin .SocialMediaShareButton,
.fixed-plugin .github-btn {
  display: inline-block;
}

.fixed-plugin li > a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
  text-decoration: none;
}

.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}

.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 0.1875rem;
  padding: 0 10px;
  position: absolute;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: #fff;
  border-radius: 3px;
}

.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.fixed-plugin .dropdown-menu > .active > a,
.fixed-plugin .dropdown-menu > .active > a:hover,
.fixed-plugin .dropdown-menu > .active > a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  height: 100px;
  margin: 0 auto;
}

.fixed-plugin .dropdown-menu li > a:hover,
.fixed-plugin .dropdown-menu li > a:focus {
  box-shadow: none;
}
.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
  background-color: rgba(30, 30, 30, 0.97);
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-purple {
  background-color: #9c27b0;
}

.fixed-plugin .badge-blue {
  background-color: #00bcd4;
}

.fixed-plugin .badge-green {
  background-color: #4caf50;
}

.fixed-plugin .badge-orange {
  background-color: #ff9800;
}

.fixed-plugin .badge-red {
  background-color: #f44336;
}

.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}
.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 4px 0px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
  padding: 0px;
  text-align: center;
}

.fixed-plugin li.adjustments-line p {
  margin: 0;
}

.fixed-plugin li.adjustments-line div,
.fixed-plugin li.header-title div,
.fixed-plugin li.button-container div {
  margin-bottom: 5px;
}
.fixed-plugin li.header-title {
  height: 30px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
  color: #3c4858;
}

.fixed-plugin .adjustments-line a {
  color: transparent;
}

.fixed-plugin .adjustments-line a .badge-colors {
  position: relative;
  top: -2px;
}

.fixed-plugin .adjustments-line a a:hover,
.fixed-plugin .adjustments-line a a:focus {
  color: transparent;
}
.fixed-plugin .adjustments-line .dropdown-menu > li.adjustments-line > a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  display: block;
  max-height: 100px;
  overflow: hidden;
  padding: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder img {
  margin-top: auto;
}
.fixed-plugin .dropdown-menu > li:hover > a.img-holder,
.fixed-plugin .dropdown-menu > li:focus > a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu > .active > a.img-holder,
.fixed-plugin .dropdown-menu > .active > a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}
.fixed-plugin .dropdown .dropdown-menu {
  -webkit-transform: translateY(-15%);
  -moz-transform: translateY(-15%);
  -o-transform: translateY(-15%);
  -ms-transform: translateY(-15%);
  transform: translateY(-15%);
  top: 27px;
  opacity: 0;
  transform-origin: 0 0;
  display: none;
}

.fixed-plugin .dropdown .dropdown-menu:before {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid rgba(0, 0, 0, 0.2);
  border-top: 0.4em solid transparent;
  right: -16px;
  top: 46px;
}
.fixed-plugin .dropdown .dropdown-menu:after {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid #ffffff;
  border-top: 0.4em solid transparent;
  right: -16px;
}

.fixed-plugin .dropdown .dropdown-menu:before,
.fixed-plugin .dropdown .dropdown-menu:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 46px;
  width: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

.fixed-plugin .dropdown.show .dropdown-menu {
  display: block;
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(-13%);
  -moz-transform: translateY(-13%);
  -o-transform: translateY(-13%);
  -ms-transform: translateY(-13%);
  transform: translateY(-13%);
  transform-origin: 0 0;
}
.fixed-plugin.rtl-fixed-plugin {
  right: auto;
  left: 0px;
  border-radius: 0 8px 8px 0;
}
.fixed-plugin.rtl-fixed-plugin .dropdown-menu {
  right: auto;
  left: 80px;
}
* {
  letter-spacing: normal !important;
}
.video-container { 
  position: relative; 
  padding-bottom: 56.25%; 
  padding-top: 30px; 
  height: 0; 
  overflow: hidden; 
}
.video-container-2 {
  height: 100%;
  margin: 0 60px;
}
.video-container-2 > video {
  height: 55vh;
}
.video-container-2 > div, .video-container-2 > div > video,.video-container-3 > div, .video-container-3 > div > video { 
  width: 100% !important;
  height: 100% !important;
}
.video-container iframe, .video-container object, .video-container embed,.video-container-3 iframe, .video-container-3 object, .video-container-3 embed { 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
}
.labelpaginationfirst {
  border-radius:30px 0 0 30px;
}
.labelpaginationlast {
  border-radius:0 30px 30px 0;
}
.labelpagination,.labelpaginationfirst, .labelpaginationlast {
  width: 110%;
  background: #cbcbcb;
  opacity: 0.6;
  display: inline-flex;
  position: relative;
  height: 24px;
  scale: 0.8;
  align-items: normal !important;
  flex-direction: unset !important;
  justify-content: normal !important;
}
.labelpagination span,.labelpaginationfirst span, .labelpaginationlast span {
  text-align:center;
  width:100%;
  align-items: center;
  display: grid;
}
.labelpaginationlast span {
  text-align:center;
  width:85%;
}
.labelpagination:after,.labelpaginationfirst:after {
  color: #cbcbcb;
  border-left: 20px solid;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  display: inline-block;
  content: '';
  position: absolute;
  right: -20px;
}
.labelpagination:before, .labelpaginationlast:before {
  color: #F9F9F7;
  border-left: 20px solid;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  display: inline-block;
  content: '';
  right: -20px;
}

.labelpaginationsel .labelpagination:after, 
.labelpaginationsel .labelpaginationfirst:after, 
.labelpaginationselprev:after {
  color: rgba(255,136,85,0.6);
  opacity: 1;
}
.labelpaginationsel > .labelpagination, 
.labelpaginationsel > .labelpaginationfirst,
.labelpaginationsel > .labelpaginationlast, 
.labelpaginationselprev  {
  background: rgba(255,136,85,0.6);
  opacity: 1;
}

.testtest {
  color: "red"
}

.word-break {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
}

.responsive, .responsive_nopointer {
  margin: 0 auto;
display: block;
max-width: 100%;
}

.responsive_nopointer2 {
  width: 100%;
  height:100%;
  object-fit: contain;
}

.pBox {
  margin-bottom: 0;
}

/**
* For pagination 
**/
.pagination {
  margin: 60px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a{
  background-color: #009df0 ;
  border-color: #009df0 ;
  color: #fff;
}
.pagination > li > a{
  border: 1px solid #009df0 ;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin: 0 5px;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  display: block;
  text-align: center;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #009df0 ;
  border-color: #009df0;
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: #009df0
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset;
  border: 0 solid #009df0 ;
  padding: 5px 10px;
  height: 100%;
  width: 100%;
  display: block;
  /*border: 1px solid #009df0;
  padding: 5px 10px;*/
}

/**
* For progress scrolling in Modules
*/
#progressBarContainer {
  position: fixed;
  margin-top:51.9px;
  z-index: 10;
  background: rgba(255,255,255,0.05);
  width: 100%;
  top: 0;
  max-width: 1920px;
}

@media only screen and (min-width: 1024px) {
  #progressBar {
    background: linear-gradient(to left, #ff8855, #ff8855);
    transform-origin: top left;
    transform: scale(0,0);
    opacity: 0;
  }

  #progressBarContainer,
  #progressBar {
    height: 10px;
  }
  /**
  * Two columns for section content
  **/
  .contentSection_columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  .contentSection_column {
    flex: 50%;
  }
  div.contentSection_column:nth-of-type(1) {
    padding-right: 15px;
  }
  div.contentSection_column:nth-of-type(2) {
    padding-left: 15px;
  }
}

/**
* FOr tooltip power react library
*/
/*.rpt-top {
  width: auto !important;
}*/

.dropdwnsty{
  font-family: 'gotham-font-book' !important;
  font-weight: 700;
  border: 1px solid #aaa;
  border-radius: .5em;
  cursor:pointer;
  font-size: .875rem;
}

.dropdwnsty, option {
  font: -moz-pull-down-menu;
}

/**
Foor tooltip custom
**/
.tooltipP {
  position: relative;
  border-bottom: 2.5px dotted #009df0;
  background-color: white !important;
  backface-visibility: hidden;
}

.tooltipP .tooltipPtext {
  visibility: hidden;
  min-width: 400px;
  background-color: white;
  color: #3c4858;
  border-radius: 6px;
  box-shadow: 0px 2px 20px lightgray;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -200px;
  padding: 10px;
}

.tooltipP .tooltipPtext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.tooltipP:hover .tooltipPtext {
  visibility: visible;
}

.modal-90w {
  max-width: 60%;
}

.carousel .control-next.control-arrow, .carousel .control-next.control-arrow:hover{
  background: rgba(0,0,0,0.2);
}

.carousel .control-prev.control-arrow, .carousel .control-prev.control-arrow:hover {
  background: rgba(0,0,0,0.2);
}

@media only screen and (max-width: 1536px) {
  .tooltipP .tooltipPtext {
    min-width: 300px;
    margin-left: -150px;
  }
}

@media only screen and (max-width: 960px) {
  .tooltipP .tooltipPtext {
    min-width: 250px;
    margin-left: -125px;
  }
  .video-container-2, .video-container-2 > video {
    height: auto;
  }
  .video-container-2 {
    margin: 0 15px;
  }
}

@media only screen and (max-width: 600px) {
  .tooltipP .tooltipPtext {
    min-width: 200px;
    margin-left: -100px;
  }
  .modal-90w {
    max-width: none;
  }
  ._loading_overlay_overlay {
    position: fixed;
  }
  .carousel .thumb {
	display: block
  }
}

.moveArrow:hover {
  animation-name : mymove;
  animation-iteration-count : infinite;
  animation-direction : alternate;
  animation-duration : 1s;
}

@keyframes mymove {
  from {transform: translateY(0px);}
  to {transform: translateY(20px);}
}

.card {
  border: 0 solid rgba(0,0,0,.125);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14);
}

.modal-content {
  border-radius: 16px !important;
}

#textform, #titleform {
  border-radius: 16px !important;
}

/*Pulse */
.arrowPulseDown {
  height: auto;
  pointer-events: none;
  animation: jumpInfinite 1.5s infinite;
}
@keyframes jumpInfinite {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0;
  }
}

/**
* For mobile-desktop text 
**/
.content-module-desktop-isfhk {
  display: inline;
}

.content-module-mobile-isfhk {
  display: none;
}

@media only screen and (max-width: 600px) {
  .content-module-desktop-isfhk {
    display: none;
  }
  
  .content-module-mobile-isfhk {
    display: inline;
  }
}

#iconModuleTransition {
  background-color: #ACD092;
  -webkit-transition: background-color 2s ease-out;
  -moz-transition: background-color 2s ease-out;
  -o-transition: background-color 2s ease-out;
  transition: background-color 2s ease-out;
}

.modal-xl {
	max-width: 1340px;
}

/**delete close icon**/
.closeContainerAM{
	position: relative;
	margin: auto;
	width: 30px;
	height: 30px;
	margin-top: 0;
	cursor: pointer;
  }
  
  .leftrightAM{
	height: 4px;
	width: 20px;
	position: absolute;
	margin-top: 0;
	background-color: #ff8855;
	border-radius: 2px;
	transform: rotate(45deg);
	transition: all .3s ease-in;
	top: 0;
	bottom: 0;
	margin: auto 0;
  }
  
  .rightleftAM{
	height: 4px;
	width: 20px;
	position: absolute;
	margin-top: 0;
	background-color: #ff8855;
	border-radius: 2px;
	transform: rotate(-45deg);
	transition: all .3s ease-in;
	top: 0;
	bottom: 0;
	margin: auto 0;
  }
  
  .closeAM{
	font-family: Helvetica, Arial, sans-serif; 
	font-size: .6em;
	text-transform: uppercase;
	letter-spacing: 2px;
	transition: all .3s ease-in;
	opacity: 0;
	margin: 25px 0 0 0px;
	position: absolute;
  }
  
  .closeContainerAM:hover .leftrightAM{
	transform: rotate(-45deg);
	background-color: rgba(203,203,203,0.2);
  }
  .closeContainerAM:hover .rightleftAM{
	transform: rotate(45deg);
	background-color: rgba(203,203,203,0.2);
  }
  .closeContainerAM:hover label{
	opacity: 1;
  }
  
  /**Scrollbar**/
::-webkit-scrollbar {
	width: 10px;
  }
  
::-webkit-scrollbar-track {
	background-color: #e4e4e4;
	border-radius: 0;
  }
  
::-webkit-scrollbar-thumb {
	background-color: #a1a1a1;
	border-radius: 100px;
  }
  

  /**date picker**/
  .react-date-picker__button .react-date-picker__button__icon {
	stroke: #ff8855;
  }

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
	stroke: rgba(255,136,85,0.6) !important;
}

.react-calendar__tile--now {
background: #009df0 !important;
}

.react-calendar {
font-family: 'gotham-font-light' !important;
font-size: 0.8em !important;
font-weight: 300;
}

.react-date-picker__wrapper {
	border-radius: 16px;
	padding: 0 10px;
}