@mixin transition() {
    transition: all .4s cubic-bezier(.215,.61,.355,1);
}

.deletebutton {
	position: relative;
	width: 30px;
	height: 30px;
}

.deletebutton a {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #ffffff;
    border-radius: 50px;
    opacity: 1;
    transition: all .4s cubic-bezier(.215,.61,.355,1);
}  

.deletebutton .left, .deletebutton .right {
      background-color: #ffe8de;
      display: block;
      height: 4px;
      border-radius: 5px;
      position: relative;
      transition: all .4s cubic-bezier(.215,.61,.355,1);
      position: absolute;
      top: 50%;
      margin-top: 4px;
      left: 2px;
      width: 22px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
}

.deletebutton span {
	display: block;
	background-color: #fdd1be;
	width: 2px;
	height: 2px;
	border-radius: 6px;
	transition: all .4s cubic-bezier(.215,.61,.355,1);
	position: absolute;
	left: 0;
	top: 0;
}

.deletebutton .left {
        transform: rotate(45deg);
        transform-origin: center;
}

.deletebutton .left .circle-left {
          transition: all .4s cubic-bezier(.215,.61,.355,1);
          margin-left: 0;
}

.deletebutton .left .circle-right {
	transition: all .4s cubic-bezier(.215,.61,.355,1);
	margin-left: 20px;
}

.deletebutton .right {
        transform: rotate(-45deg);
        transform-origin: center; 
}

.deletebutton .right .circle-left {
          transition: all .4s cubic-bezier(.215,.61,.355,1);
          margin-left: 0;
}

.deletebutton .right .circle-right {
          transition: all .4s cubic-bezier(.215,.61,.355,1);
          margin-left: 20px;
        }

.deletebutton:hover .left ,.deletebutton:hover .right {
        background-color: #ff8855;
        transition: all .4s cubic-bezier(.215,.61,.355,1);
}

.deletebutton:hover span {
          transition: all .4s cubic-bezier(.215,.61,.355,1);
          background-color: #ff6827;
}

.deletebutton:hover .left .circle-left {
            transition: all .4s cubic-bezier(.215,.61,.355,1);
            margin-left: 20px;
          }

.deletebutton:hover .left .circle-right {
            transition: all .4s cubic-bezier(.215,.61,.355,1);
            margin-left: 0;
          }

.deletebutton:hover .right .circle-left {
            transition: all .4s cubic-bezier(.215,.61,.355,1);
            margin-left: 20px;
}

.deletebutton:hover .right .circle-right {
            transition: all .4s cubic-bezier(.215,.61,.355,1);
            margin-left: 0;
          }