.modulePages .card-wrapper {
display: inline-block;
perspective: 1000px;
}
@media only screen and (max-width: 600px) {
    .modulePages div[style] {
        width: 100% !important;
        /*height: 200px !important;*/
    }
}
.modulePages .card-wrapper .card {
position: relative;
cursor: pointer;
transition-duration: 0.6s;
transition-timing-function: ease-in-out;
transform-style: preserve-3d;
-webkit-transform-style: preserve-3d;
-moz-transform-style: preserve-3d;
-ms-transform-style: preserve-3d;
-webkit-backface-visibility: hidden; 
}
@media only screen and (max-width: 600px) {
    .modulePages .card-wrapper div[style] {
        width: 100% !important;
        /*height: 200px !important;*/
    }
}
.modulePages .card-wrapper .card .front,
.modulePages .card-wrapper .card .back {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
-webkit-backface-visibility: hidden;
backface-visibility: hidden;
transform: rotateX(0deg);
padding: 20px;
}
.modulePages .card-wrapper .card .front {
z-index: 2;
}
.modulePages .card-wrapper .card .back,
.modulePages .card-wrapper.flip-right .card .back {
transform: rotateY(180deg);
}
.modulePages .card-wrapper.flip-left .card .back {
transform: rotateY(-180deg);
}
.modulePages .card-wrapper.flip-up .card .back {
transform: rotateX(180deg);
}
.modulePages .card-wrapper.flip-down .card .back {
transform: rotateX(-180deg);
}
.modulePages .card-wrapper.flip-diagonal-right .card .back {
transform: rotate3d(1, 1, 0, 180deg);
}
.modulePages .card-wrapper.flip-diagonal-left .card .back {
transform: rotate3d(1, 1, 0, -180deg);
}
.modulePages .card-wrapper.flip-inverted-diagonal-right .card .back {
transform: rotate3d(-1, 1, 0, 180deg);
}
.modulePages .card-wrapper.flip-inverted-diagonal-left .card .back {
transform: rotate3d(1, -1, 0, 180deg);
}

/* DEMO */
body {
background: #E7BDD6;
}
.modulePages .card-wrapper,
.modulePages .card {
min-width: 200px;
min-height: 200px;
margin: 10px;
border-radius: 16px !important;
}
.modulePages .card .front {
display: flex;
align-items: center;
justify-content: center;
border: 1px solid #ff8855;
border-radius: 16px;
}
.modulePages .card .back {
border: 1px solid #ff8855;
border-radius: 16px;
}
.modulePages .card .front {
color: #000000;
background: #FFFFFF;
font-weight: 700;
}
.modulePages .card .back {
color: #000000;
background: #DEECD5;
}
@media only screen and (max-width: 600px) {
    .modulePages .card-wrapper div[style] {
        margin: 0;
    }
    .modulePages .card-wrapper,
    .modulePages .card {
        margin:auto;
    }
}